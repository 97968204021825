import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CheckIcon from '@mui/icons-material/Check';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Link from '@mui/material/Link';

//visum suche specific
import flag from '../assets/icons/flags/ind.svg';
import reisepassVisum from '../assets/images/countries/india/visa-india-paper-600width.jpg';
import eVisum from '../assets/images/countries/india/visa-india-e-visa-265x282.jpg';
import visumApplication from '../assets/images/countries/india/india-evisa-application.jpg';
import VList from '../components/custom_components/VList';
import VStepHeading from '../components/custom_components/VStepHeading';


export default () => {
  function createVisaVendors(visavendor: string, price: string | JSX.Element, website: string) {
    return { visavendor, price, website };
  }
  const tooltip = (
    <>
      <Typography>70 - 110€</Typography>
      <Tooltip title="je nach Bearbeitungsdauer">
        <IconButton size="large">
          <InfoIcon />
        </IconButton>
      </Tooltip>
    </>
  );

  const visavendors = [
    createVisaVendors(
      'Visumantrag',
      '109,95 €',
      'https://visumantrag.de/indien',
    ),
    createVisaVendors(
      'König Tours',
      tooltip,
      'https://www.buch-dein-visum.de/indien-visum-beantragen.htm',
    ),
    createVisaVendors(
      'Visabox',
      '199,99 €',
      'https://www.visabox.de/touristenvisum-indien.html',
    ),
  ];

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Box component='img' alt='Indien Flagge' src={flag} sx={{maxWidth: '70px', maxHeight: '60px', mr: 2,}} />
        <Typography variant="h1">Visum für Indien</Typography>
      </Box>

      <Typography variant="h6" component="p">
        Welches Visum du für deine Reise nach Indien benötigst und wie es
        schnell, günstig und sicher klappt erkläre ich dir hier.
      </Typography>

      {/* instruction */}
      <Typography variant="h2">
        Diese verschiedenen Visa gibt es für Indien
      </Typography>

      <VList
        contentArray={[
          'e-Tourist-Visum',
          'e-Business Visum',
          'e-Medical Visum',
          'e-Medical Attendant Visum',
          'e-Conference Visum',
        ]}
      />

      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={6}
          order={{ xs: 1, sm: 1 }}
          sx={{ display: 'flex' }}
        >
          <Paper
            elevation={2}
            sx={{
              display: 'flex',
              alignSelf: 'start',
              borderRadius: 1,
              overflow: 'hidden',
            }}
          >
            <img
              src={reisepassVisum}
              alt="Indien Reisevisum Reisepass Aufkleber Papier"
              height="auto"
              width="100%"
            />
          </Paper>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          order={{ xs: 3, sm: 2 }}
          sx={{ display: 'flex' }}
        >
          <Paper
            elevation={2}
            sx={{
              display: 'flex',
              alignSelf: 'start',
              borderRadius: 1,
              overflow: 'hidden',
            }}
          >
            <img
              src={eVisum}
              alt="Indien Reisevisum eVisum Stempel im Reisepass"
              height="auto"
              width="100%"
            />
          </Paper>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          order={{ xs: 2, sm: 3 }}
          sx={{ display: 'flex' }}
        >
          <Typography>
            <strong>reguläres Visum</strong> (Visumaufkleber) <br />
            Dies ist das Visum im klassischen Sinne, welches man bei dem
            Konsulat erhält und auf eine Seite im Reisepass eingeklebt wird.
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          order={{ xs: 4, sm: 4 }}
          sx={{ display: 'flex' }}
        >
          <Typography>
            <strong>e-Visum</strong> <br />
            Das e-Visum ist die neue unkomplizierte Variante. Den Antrag stellt
            man online und erhält den e-Visum Stempel bei der Einreise in
            Indien.
          </Typography>
        </Grid>
      </Grid>

      <Typography variant="body1" sx={{ mt: 2 }}>
        Falls es dir möglich ist ein e-Visum zu beantragen, solltest du das
        e-Visum für Indien bevorzugen. Hier die Vorteile:
      </Typography>
      <VList
        contentArray={[
          'Kann vollständig online beantragt werden',
          'Günstiger als das klassische Visum',
          'Man muss nicht zur Botschaft oder den Reisepass per Post senden',
          'Kürzere Bearbeitungsdauer',
        ]}
        icon={<CheckIcon sx={{ color: 'success.main' }} />}
      />

      <Typography variant="h2">
        Wann du ein reguläres Visum benötigst
      </Typography>

      <VList
        contentArray={[
          'Wenn du als Tourist länger als 90 Tage in Indien verbringen möchtest',
          'Wenn du aus geschäftlichen Gründer länger als 180 Tage in Indien bist',
          'Bei ehrenamtliche Tätigkeit, aufnehmen eines Arbeitsverhältnisses oder das Studieren in Indien',
          'Bei Einreise nicht über einen der üblichen internationalen indischen Flughäfen oder Seehäfen. Die genaue Liste findest du hier:',
        ]}
      />

      <Accordion>
        <AccordionSummary
          expandIcon={<ControlPointIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Erlaubte Einreise Flughafen/Seehafen</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <strong>Flughafen:</strong> <br />
            Delhi, Mumbai, Chennai, Kolkata, Thiruvananthapuram, Bengaluru,
            Hyderabad, Cochin, Goa, Ahmedabad, Amritsar, Gaya, Jaipur, Lucknow,
            Trichy, Varanasi, Calicut, Mangalore, Pune, Nagpur, Coimbatore,
            Bagdogra, Guwahati, Chandigarh, Visakhapatnam, Madurai, Bhubaneswar,
            Port Blair
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <strong>Seehafen</strong> <br />
            Mumbai, Cochin, Mormugao, Chennai, New Mangalore
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Typography variant="h2">
        So beantragst du das e-Visum für Indien richtig
      </Typography>

      <Typography variant="h3">
        Wann das e-Visum beantragt werden muss
      </Typography>
      <Typography>
        Beantrag das e-Tourist und e-Business Visum mindestens 4 Tage vor der
        der Einreise. Das e-Medical, e-Medical Attendant und e-Conference Visa
        müssen mindestens 4 Tage bis maximal 120 Tage vor der Einreise beantragt
        werden.
      </Typography>

      <Typography variant="h3">
        Schritt-für-Schritt Anleitung für das e-Visum Indien
      </Typography>
      <Typography>
        Das musst du für den Antrag deines e-Visums beachten.
      </Typography>

      <VStepHeading stepNumber={1} stepHierarchy='h4' stepHeading='Online-Formular' />
      <Typography>
        Online-Formular ausfüllen & Visumseite sowie Bild hochladen (ca. 20
        Minuten) Hier findet man das{' '}
        <Link href="https://indianvisaonline.gov.in/evisa/tvoa.html">
          offizielle Visum Online-Formular
        </Link>
        . Zum auszufüllen benötigst du folgende Dokumente:
      </Typography>

      <VList
        contentArray={[
          'Das Passbild muss das Gesicht von vorne vor einem weißen Hintergrund abbilden, darf keine Ränder und Schatten haben. Wenn man sich geschickt anstellt geht das vor einer weißen Wand. Das Bild muss quadratisch zugeschnitten sein, eine Größe haben und im JPEG Format sein (Größe 10 KB – 1 MB)',
          'Ein Scan der ersten Seite aus dem Reisepasse (die Seite mit dem Passbild, Gültigkeit etc.) im PDF Format (Größe: 10 KB – 300 KB)',
        ]}
      />
      <Paper
        elevation={2}
        sx={{ display: 'flex', borderRadius: 1, overflow: 'hidden' }}
      >
        <img
          src={visumApplication}
          alt="Bewerbung für eVisa bei der indischen Botschaft"
          height="auto"
          width="100%"
        />
      </Paper>

      <Divider />

      <VStepHeading stepNumber={2} stepHierarchy='h4' stepHeading='Visum bezahlen (Bestätigung dauert max. 2 Stunden)' />
      <Typography>
        Nach dem Ausfüllen des Formulars zahlt man die Gebühr für das e-Visum.
        Als Bezahlmöglichkeiten gibt es Master Card, Visa und PayPal. Die
        Zahlungsbestätiung sollte man spätestens nach 4 Stunden erhalten. In
        meinem Fall war sie in einer halben Stunde da.
      </Typography>

      <Divider />

      <VStepHeading stepNumber={3} stepHierarchy='h4' stepHeading='Visum erhalten (Prüfung 1-4 Tage)' />
      <Typography>
        Den Status des e-Visa Antrags kann man hier online abrufen, dazu einfach
        auf “Check your Visa Status” in der unteren Leiste klicken, wie im Bild
        oben gezeigt. Ist der Antrag erfolgreich erhält man die Bestätigung, die
        man ausdruckt und bei der Einreise (evtl. bereits beim Checkin)
        vorzeigen muss.
      </Typography>

      <Typography variant="h2">
        Alternative: Der e-Visum Antrag über einen Dienstleister
      </Typography>
      <Typography>
        Der Weg über einen der folgenden Anbieter ist nicht der Direkte und
        daher etwas teurer. Allerdings kann dies dir die Abwicklung deutlich
        erleichtern. Die Formulare sind verständlicher und bei Fragen kannst du
        dich einfach an den Anbieter wenden.
      </Typography>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead sx={{ backgroundColor: 'primary.main' }}>
            <TableRow>
              <TableCell sx={{ color: 'white' }}>Visum Dienstleister</TableCell>
              <TableCell sx={{ color: 'white' }}>Preis</TableCell>
              <TableCell sx={{ color: 'white' }}>Website</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visavendors.map((row) => (
              <TableRow key={row.visavendor}>
                <TableCell>{row.visavendor}</TableCell>
                <TableCell>{row.price}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    size="small"
                    endIcon={<OpenInNewIcon />}
                    href={row.website}
                    target="_blank"
                  >
                    Zum Anbieter
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
