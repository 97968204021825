import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export default function NotFound() {
  return (
    <Container
      maxWidth={false}
      sx={{
        //100vh - header - footer
        height: 'calc(100vh - 64px - 82px)',
        background: 'linear-gradient(45deg, #7134E7 30%, #FF8E53 90%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h1" sx={{ color: '#ffffff' }}>
        404 - Not Found 🥺
      </Typography>
    </Container>
  );
}
