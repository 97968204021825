import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';

interface VStepHeadingProps {
  stepNumber: number;
  stepHierarchy: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  stepHeading: string;
}

export default function VStepHeading ({stepNumber, stepHierarchy, stepHeading}:VStepHeadingProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
      <Box
        sx={{
          borderRadius: '50%',
          backgroundColor: grey[200],
          height: '50px',
          width: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          component="p"
          sx={{
            color: 'primary.main',
            m: 0,
            fontWeight: 900,
            fontSize: '1.6rem',
          }}
        >
          {stepNumber}
        </Typography>
      </Box>
      <Typography variant="h6" component={stepHierarchy} sx={{ ml: 1, mt: 0 }}>
        {stepHeading}
      </Typography>
    </Box>
  );
};
