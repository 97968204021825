import type { AutocompleteComponents } from '@algolia/autocomplete-js';
import { Hit } from '@algolia/client-search';
import Flag from '../components/Flag';

export type CountryHit = Hit<{
  name: string;
  slug: string;
  iso_alpha_3: string;
}>

interface CountryItemProps {
  hit: CountryHit;
  components: AutocompleteComponents;
}

export default function CountryItem({ hit, components }: CountryItemProps) {
  return (
    <a href={`${process.env.REACT_APP_BASE_URL}/land/${hit.slug}`} className="aa-ItemLink">
      <div className="aa-ItemContent">
        <div className="aa-ItemIcon aa-ItemIcon--noBorder">
          <Flag
              iso={hit.iso_alpha_3}
              name={hit.name}
            />
        </div>
        <div className="aa-ItemTitle">
          <components.Highlight hit={hit} attribute="name" />
        </div>
      </div>
    </a>
  );
}