import { Outlet } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/system';
import CssBaseline from '@mui/material/CssBaseline';
import theme from '../theme';
import Header from './Header';
import MainNavigation from '../navigation/MainNavigation';
import Footer from './Footer';

export default function BaseLayout() {
  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline>
            <Header />
            <MainNavigation />
            <Outlet />
            <Footer />
          </CssBaseline>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}
