export default function Datenschutzerklaerung() {
  return (
    <>
      <h1>Datenschutzerklärung</h1>
      <h2>Datenschutz</h2>
      Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
      sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
      entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
      Datenschutzerklärung. Die Nutzung unserer Webseite ist in der Regel ohne
      Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten
      personenbezogene Daten (beispielsweise Name, Anschrift oder
      E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf
      freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung
      nicht an Dritte weitergegeben. Wir weisen darauf hin, dass die
      Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
      Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem
      Zugriff durch Dritte ist nicht möglich. &nbsp;
      <h2>Cookies</h2>
      Die Internetseiten verwenden teilweise so genannte Cookies. Cookies
      richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.
      Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und
      sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner
      abgelegt werden und die Ihr Browser speichert. Die meisten der von uns
      verwendeten Cookies sind so genannte „Session-Cookies“. Sie werden nach
      Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem
      Endgerät gespeichert, bis Sie diese löschen. Diese Cookies ermöglichen es
      uns, Ihren Browser beim nächsten Besuch wiederzuerkennen. Sie können Ihren
      Browser so einstellen, dass Sie über das Setzen von Cookies informiert
      werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für
      bestimmte Fälle oder generell ausschließen sowie das automatische Löschen
      der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung
      von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
      &nbsp;
      <h2>Server-Log- Files</h2>
      Der Provider der Seiten erhebt und speichert automatisch Informationen in
      so genannten Server-Log Files, die Ihr Browser automatisch an uns
      übermittelt. Dies sind:
      <ul>
        <li>Browsertyp und Browserversion</li>
        <li>verwendetes Betriebssystem</li>
        <li>Referrer URL</li>
        <li>Hostname des zugreifenden Rechners</li>
        <li>Uhrzeit der Serveranfrage</li>
      </ul>
      Diese Daten sind nicht bestimmten Personen zuordenbar. Eine
      Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
      vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen,
      wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt
      werden. &nbsp;
      <h2>Kontaktformular</h2>
      Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
      Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen
      Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
      Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre
      Einwilligung weiter. &nbsp;
      <h2>Google Analytics</h2>
      Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics.
      Anbieter ist die Google Inc., 1600 Amphitheatre Parkway Mountain View, CA
      94043, USA. Google Analytics verwendet so genannte "Cookies". Das sind
      Textdateien, die auf Ihrem Computer gespeichert werden und die eine
      Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den
      Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden
      in der Regel an einen Server von Google in den USA übertragen und dort
      gespeichert. Mehr Informationen zum Umgang mit Nutzerdaten bei Google
      Analytics finden Sie in der Datenschutzerklärung von Google:{' '}
      <a href="https://support.google.com/analytics/answer/6004245?hl=de">
        {' '}
        https://support.google.com/analytics/answer/6004245?hl=de
      </a>
      <strong>Browser Plugin</strong>
      Sie können die Speicherung der Cookies durch eine entsprechende
      Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
      darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
      Funktionen dieser Website vollumfänglich werden nutzen können. Sie können
      darüber hinaus die Erfassung der durch den Cookie erzeugten und auf Ihre
      Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google
      sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das
      unter dem folgenden Link verfügbare Browser- Plugin herunterladen und
      installieren:{' '}
      <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
        {' '}
        https://tools.google.com/dlpage/gaoptout?hl=de
      </a>
      <strong>Widerspruch gegen Datenerfassung</strong>
      Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern,
      indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt,
      der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser Website
      verhindert: Google Analytics deaktivieren
      <strong>Auftragsdatenverarbeitung</strong>
      Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung
      abgeschlossen und setzen die strengen Vorgaben der deutschen
      Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.
      <strong>IP-Anonymisierung</strong>
      Wir nutzen die Funktion "Aktivierung der IP-Anonymisierung" auf dieser
      Webseite. Dadurch wird Ihre IP-Adresse von Google jedoch innerhalb von
      Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des
      Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in
      Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den
      USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website
      wird Google diese Informationen benutzen, um Ihre Nutzung der Website
      auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und
      um weitere mit der Websitenutzung und der Internetnutzung verbundene
      Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im
      Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird
      nicht mit anderen Daten von Google zusammengeführt.
      <strong>Demografische Merkmale bei Google Analytics</strong>
      Diese Website nutzt die Funktion “demografische Merkmale” von Google
      Analytics. Dadurch können Berichte erstellt werden, die Aussagen zu Alter,
      Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten
      stammen aus interessenbezogener Werbung von Google sowie aus Besucherdaten
      von Drittanbietern. Diese Daten können keiner bestimmten Person zugeordnet
      werden. Sie können diese Funktion jederzeit über die Anzeigeneinstellungen
      in Ihrem Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch
      Google Analytics wie im Punkt “Widerspruch gegen Datenerfassung”
      dargestellt generell untersagen. &nbsp;
      <h2>Facebook-Plugins (Like-Button)</h2>
      Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook, Anbieter
      Facebook Inc., 1 Hacker Way, Menlo Park, California 94025, USA,
      integriert. Die Facebook-Plugins erkennen Sie an dem Facebook-Logo oder
      dem "Like- Button" ("Gefällt mir") auf unserer Seite. Eine Übersicht über
      die Facebook-Plugins finden Sie hier:{' '}
      <a href="http://developers.facebook.com/docs/plugins/">
        {' '}
        http://developers.facebook.com/docs/plugins/
      </a>
      . Wenn Sie unsere Seiten besuchen, wird über das Plugin eine direkte
      Verbindung zwischen Ihrem Browser und dem Facebook-Server hergestellt.
      Facebook erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse
      unsere Seite besucht haben. Wenn Sie den Facebook "Like-Button" anklicken
      während Sie in Ihrem Facebook- Account eingeloggt sind, können Sie die
      Inhalte unserer Seiten auf Ihrem Facebook-Profil verlinken. Dadurch kann
      Facebook den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir
      weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom
      Inhalt der übermittelten Daten sowie deren Nutzung durch Facebook
      erhalten. Weitere Informationen hierzu finden Sie in der
      Datenschutzerklärung von Facebook unter{' '}
      <a href="http://de-de.facebook.com/policy.php">
        http://de- de.facebook.com/policy.php
      </a>
      . Wenn Sie nicht wünschen, dass Facebook den Besuch unserer Seiten Ihrem
      Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem
      Facebook- Benutzerkonto aus. &nbsp;
      <h2>Twitter</h2>
      Auf unseren Seiten sind Funktionen des Dienstes Twitter eingebunden. Diese
      Funktionen werden angeboten durch die Twitter Inc., 1355 Market Street,
      Suite 900, San Francisco, CA 94103, USA. Durch das Benutzen von Twitter
      und der Funktion "Re- Tweet" werden die von Ihnen besuchten Webseiten mit
      Ihrem Twitter-Account verknüpft und anderen Nutzern bekannt gegeben. Dabei
      werden auch Daten an Twitter übertragen. Wir weisen darauf hin, dass wir
      als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten
      sowie deren Nutzung durch Twitter erhalten. Weitere Informationen hierzu
      finden Sie in der Datenschutzerklärung von Twitter unter{' '}
      <a href="http://twitter.com/privacy"> http://twitter.com/privacy</a>. Ihre
      Datenschutzeinstellungen bei Twitter können Sie in den Konto-Einstellungen
      unter:{' '}
      <a href="http://twitter.com/account/settings">
        {' '}
        http://twitter.com/account/settings
      </a>{' '}
      ändern. &nbsp;
      <h2>Google+</h2>
      Unsere Seiten nutzen Funktionen von Google+. Anbieter ist die Google Inc.,
      1600 Amphitheatre Parkway Mountain View, CA 94043, USA. Erfassung und
      Weitergabe von Informationen: Mithilfe der Google+-Schaltfläche können Sie
      Informationen weltweit veröffentlichen. Über die Google+-Schaltfläche
      erhalten Sie und andere Nutzer personalisierte Inhalte von Google und
      unseren Partnern. Google speichert sowohl die Information, dass Sie für
      einen Inhalt +1 gegeben haben, als auch Informationen über die Seite, die
      Sie beim Klicken auf +1 angesehen haben. Ihre +1 können als Hinweise
      zusammen mit Ihrem Profilnamen und Ihrem Foto in Google-Diensten, wie etwa
      in Suchergebnissen oder in Ihrem Google-Profil, oder an anderen Stellen
      auf Websites und Anzeigen im Internet eingeblendet werden. Google zeichnet
      Informationen über Ihre +1- Aktivitäten auf, um die Google-Dienste für Sie
      und andere zu verbessern. Um die Google+- Schaltfläche verwenden zu
      können, benötigen Sie ein weltweit sichtbares, öffentliches Google-Profil,
      das zumindest den für das Profil gewählten Namen enthalten muss. Dieser
      Name wird in allen Google-Diensten verwendet. In manchen Fällen kann
      dieser Name auch einen anderen Namen ersetzen, den Sie beim Teilen von
      Inhalten über Ihr Google-Konto verwendet haben. Die Identität Ihres
      Google-Profils kann Nutzern angezeigt werden, die Ihre E-Mail- Adresse
      kennen oder über andere identifizierende Informationen von Ihnen verfügen.
      Verwendung der erfassten Informationen: Neben den oben erläuterten
      Verwendungszwecken werden die von Ihnen bereitgestellten Informationen
      gemäß den geltenden Google- Datenschutzbestimmungen genutzt. Google
      veröffentlicht möglicherweise zusammengefasste Statistiken über die
      +1-Aktivitäten der Nutzer bzw. gibt diese an Nutzer und Partner weiter,
      wie etwa Publisher, Inserenten oder verbundene Websites. &nbsp;
      <h2>Instagram</h2>
      Auf unseren Seiten sind Funktionen des Dienstes Instagram eingebunden.
      Diese Funktionen werden angeboten durch die Instagram Inc., 1601 Willow
      Road, Menlo Park, CA, 94025, USA integriert. Wenn Sie in Ihrem
      Instagram-Account eingeloggt sind können Sie durch Anklicken des
      Instagram-Buttons die Inhalte unserer Seiten mit Ihrem Instagram-Profil
      verlinken. Dadurch kann Instagram den Besuch unserer Seiten Ihrem
      Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der
      Seiten keine Kenntnis vom Inhalt der u?bermittelten Daten sowie deren
      Nutzung durch Instagram erhalten. Weitere Informationen hierzu finden Sie
      in der Datenschutzerklärung von Instagram:{' '}
      <a href="http://instagram.com/about/legal/privacy/">
        http://instagram.com/about/legal/privacy/
      </a>
      &nbsp;
      <h2>LinkedIn</h2>
      Unsere Website nutzt Funktionen des Netzwerks LinkedIn. Anbieter ist die
      LinkedIn Corporation, 2029 Stierlin Court, Mountain View, CA 94043, USA.
      Bei jedem Abruf einer unserer Seiten, die Funktionen von LinkedIn enthält,
      wird eine Verbindung zu Servern von LinkedIn aufbaut. LinkedIn wird
      darüber informiert, dass Sie unsere Internetseiten mit Ihrer IP-Adresse
      besucht haben. Wenn Sie den "Recommend-Button" von LinkedIn anklicken und
      in Ihrem Account bei LinkedIn eingeloggt sind, ist es LinkedIn möglich,
      Ihren Besuch auf unserer Internetseite Ihnen und Ihrem Benutzerkonto
      zuzuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine
      Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch
      LinkedIn haben. Weitere Informationen hierzu finden Sie in der
      Datenschutzerklärung von LinkedIn unter:{' '}
      <a href="https://www.linkedin.com/legal/privacy-policy">
        https://www.linkedin.com/legal/privacy-policy
      </a>
      &nbsp;
      <h2>Pinterest</h2>
      Auf unserer Seite verwenden wir Social Plugins des sozialen Netzwerkes
      Pinterest, das von der Pinterest Inc., 808 Brannan Street San Francisco,
      CA 94103-490, USA ("Pinterest") betrieben wird. Wenn Sie eine Seite
      aufrufen, die ein solches Plugin enthält, stellt Ihr Browser eine direkte
      Verbindung zu den Servern von Pinterest her. Das Plugin übermittelt dabei
      Protokolldaten an den Server von Pinterest in die USA. Diese
      Protokolldaten enthalten möglicherweise Ihre IP-Adresse, die Adresse der
      besuchten Websites, die ebenfalls Pinterest- Funktionen enthalten, Art und
      Einstellungen des Browsers, Datum und Zeitpunkt der Anfrage, Ihre
      Verwendungsweise von Pinterest sowie Cookies. Weitere Informationen zu
      Zweck, Umfang und weiterer Verarbeitung und Nutzung der Daten durch
      Pinterest sowie Ihre diesbezüglichen Rechte und Möglichkeiten zum Schutz
      Ihrer Privatsphäre finden Sie in den den Datenschutzhinweisen von
      Pinterest:{' '}
      <a href="https://about.pinterest.com/de/privacy-policy">
        {' '}
        https://about.pinterest.com/de/privacy-policy
      </a>
      &nbsp;
      <h2>XING</h2>
      Unsere Webseite nutzt Funktionen des Netzwerks XING. Anbieter ist die XING
      AG, Dammtorstraße 29-32, 20354 Hamburg, Deutschland. Bei jedem Abruf einer
      unserer Seiten, die Funktionen von XING enthält, wird eine Verbindung zu
      Servern von XING hergestellt. Eine Speicherung von personenbezogenen Daten
      erfolgt dabei nach unserer Kenntnis nicht. Insbesondere werden keine IP-
      Adressen gespeichert oder das Nutzungsverhalten ausgewertet. Weitere
      Information zum Datenschutz und dem XING Share-Button finden Sie in der
      Datenschutzerklärung von XING unter:{' '}
      <a href="https://www.xing.com/app/share?op=data_protection">
        {' '}
        https://www.xing.com/app/share?op=data_protection
      </a>
      &nbsp;
      <h2>YouTube</h2>
      Unsere Webseite nutzt Plugins der von Google betriebenen Seite YouTube.
      Betreiber der Seiten ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA
      94066, USA. Wenn Sie eine unserer mit einem YouTube- Plugin ausgestatteten
      Seiten besuchen, wird eine Verbindung zu den Servern von YouTube
      hergestellt. Dabei wird dem Youtube-Server mitgeteilt, welche unserer
      Seiten Sie besucht haben. Wenn Sie in Ihrem YouTube-Account eingeloggt
      sind ermöglichen Sie YouTube, Ihr Surfverhalten direkt Ihrem persönlichen
      Profil zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem
      YouTube-Account ausloggen. Weitere Informationen zum Umgang von
      Nutzerdaten finden Sie in der Datenschutzerklärung von YouTube unter:{' '}
      <a href="https://www.google.de/intl/de/policies/privacy">
        https://www.google.de/intl/de/policies/privacy
      </a>
      &nbsp;
      <h2>Kommentarfunktion auf dieser Webseite</h2>
      Für die Kommentarfunktion auf dieser Seite werden neben Ihrem Kommentar
      auch Angaben zum Zeitpunkt der Erstellung des Kommentars, Ihre
      E-Mail-Adresse und, wenn Sie nicht anonym posten, der von Ihnen gewählte
      Nutzername gespeichert. &nbsp;
      <h2>SSL-Verschlüsselung</h2>
      Diese Seite nutzt aus Gründen der Sicherheit und zum Schutz der
      Übertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die Sie
      an uns als Seitenbetreiber senden, eine SSL-Verschlüsselung. Eine
      verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des
      Browsers von "http://" auf "https://" wechselt und an dem Schloss-Symbol
      in Ihrer Browserzeile. Wenn die SSL Verschlüsselung aktiviert ist, können
      die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
      werden. &nbsp; Quelle: e-recht24
    </>
  );
}
