import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';
import { ListItemText } from '@mui/material';
import AutocompleteSearch from '../../navigation/AutocompleteSearch';

export default function Homepage() {
  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      sx={{
        background: 'linear-gradient(45deg, #7134E7 30%, #FF8E53 90%)',
        minHeight: 'calc(100vh - 64px)', // 100%vh - Appbar Height
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          color="inherit"
          align="center"
          sx={{
            fontSize: {
              xs: '2rem',
              md: '4rem',
            },
            color: '#fff',
            fontWeight: 600,
            pt: { xs: 3, md: 0 },
          }}
        >
          Vergleiche und erhalte dein Visum zuverlässig & schnell
        </Typography>
        <Typography
          variant="h5"
          color="inherit"
          align="center"
          paragraph
          sx={{
            color: '#fff',
            marginTop: 3,
            opacity: 0.8,
            fontSize: {
              xs: '1.2rem',
              md: '1.5rem',
            },
          }}
        >
          Finde den besten Anbieter mit dem günstigsten Preis für dein
          Reisevisum.
        </Typography>

        {/* homepage search */}
          <Box sx={{
            pt: 3,
            pb: 3,
            maxWidth: '400px',
            margin: 'auto'
          }}>
            <AutocompleteSearch addClass={{root: 'homepage-search'}} placeholder='Dein Zielland...' autoFocus={true}/>
          </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            color: 'white',
            opacity: 0.8,
            mt: 1,
          }}
        >
          <List>
            {['Günstigster Preis', 'Transparent', 'Einfach'].map((text) => (
              <ListItem sx={{ p: 0.5 }} key={text}>
                <ListItemIcon>
                  <CheckIcon sx={{ color: 'white', opacity: 0.8 }} />
                </ListItemIcon>
                <ListItemText
                  primary={text}
                  primaryTypographyProps={{ fontWeight: 'bold' }}
                  sx={{ ml: -3 }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Container>
    </Container>
  );
}
