import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      lighter: '#E1D1FF',
      light: '#9567F0',
      main: '#4515a2',
      dark: '#2F0F70',
    },
    success: {
      light: '#73F1C4',
      main: '#13bf81',
      dark: '#0c9061',
      darker: '#03744C',
    },
    error: {
      light: '#FF7B7B',
      main: '#FF4C4C',
      dark: '#CB1D1D',
    },
    // grey: not used yet
    grey: {
      lighter: grey[100],
      light: grey[300],
      main: grey[500],
      dark: grey[700],
      darker: grey[900],
    },
  },
  typography: {
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontSize: '3.5rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '2.8rem',
      fontWeight: 700,
      letterSpacing: '-0.05rem',
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.7rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '1.5rem',
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 700,
    },
    body1: {
      fontSize: '1.1rem',
    },

    //will be used for text in tables
    body2: {
      fontSize: '1.1rem',
    },
  },
  shape: {
    borderRadius: 10,
  },
  //Component Overrides
  components: {
    MuiButton: {
      styleOverrides: {
        //name of the slot
        root: {
          fontSize: '1rem',
          fontWeight: 700,
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          marginTop: 16,
          marginBottom: 16,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          marginTop: 8,
          marginBottom: 8,
        },
      },
    },
  },
});

export default theme;
