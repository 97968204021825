import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

//visum suche specific
import { getCountry } from '../../api/apiRequests';
import Load from '../../components/states/Load';
import InProcess from '../../components/states/InProcess';
import DetailPageHeading from './DetailPageHeading';
import Embassies from '../../components/Embassies/Embassies';
import VisaTypes from '../../components/Visas/VisaTypes';
import { Country } from '../../api/models';
import Box from '@mui/material/Box';

export default function CountryDetailPage() {
  /* slug can be string or undefined */
  let { slug } = useParams<{slug?: string}>();

  const [country, setCountry] = useState<Country | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadCountry = async () => {
      try{
        const response = await getCountry(slug);
        setCountry(response.data);
        setLoading(false);
      }
      catch (error) {
        setCountry(null);
        setLoading(false);
        console.log(error);
      }
    };
    loadCountry();
  }, [slug]); // rerenders when slug changes

  // show loadingscreen till api request is finished or when error occurs
  if (loading === true || country === null) {
    return <Load />;
  }

  // don't show country when visible is set to false
  else if (!country.visible) {
    return <InProcess iso={country.iso_alpha_3} name={country.name} />;
  }

  return (
    <Box sx={{minHeight: 'calc(100vh - 128px)'}}>
      {/* <VCoronaBox content="xxx" /> */}
      <DetailPageHeading name={country.name} iso3={country.iso_alpha_3} />

      {/* show embassies and visas when information exists */}
      {/* {country.visas && <VisaTypes visaLinks={country.visas} />} */}
      
      {country.embassies && (
        <Embassies embassyLinks={country.embassies} countryName={country.name} />
      )}
    </Box>
  );
}
