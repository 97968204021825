import { Routes, Route } from 'react-router-dom';

import Homepage from '../pages/Homepage/Homepage';
import Impressum from '../components/Impressum';
import Datenschutzerklaerung from '../components/Datenschutzerklaerung';
import BaseLayout from '../components/BaseLayout';
import BoxedLayout from '../components/BoxedLayout';
import NotFound from './NotFound';
import DemoFrontendComponents from '../components/DemoFrontendComponents';

import IndiaPage from '../pages/IndiaPage';
import CubaPage from '../pages/CubaPage';
import RussiaPage from '../pages/RussiaPage';

import Test from '../components/xxx_test';

import CountryList from '../pages/CountryList/CountryList';
import CountryDetailPage from '../pages/CountryDetailPage/CountryDetailPage';

export default function RouterConfig() {
  return (
    <Routes>
      <Route element={<BaseLayout />}>
        <Route index element={<Homepage />} />

        {/* not found - 404 route */}
        <Route path="*" element={<NotFound />} />
      </Route>

      {/* layout route: without a path, has to has an element so the child route replaces the outlet, it wont work when nested already in layout route element*/}
      <Route element={<BaseLayout />}>
        <Route element={<BoxedLayout />}>
          <Route path="/impressum" element={<Impressum />} />
          <Route
            path="/datenschutzerklaerung"
            element={<Datenschutzerklaerung />}
          />

          <Route path="/indien" element={<IndiaPage />} />
          <Route path="/kuba" element={<CubaPage />} />
          <Route path="/russland" element={<RussiaPage />} />

          <Route path="/test" element={<Test />} />
          <Route path="/demo" element={<DemoFrontendComponents />} />

          <Route path="/laender" element={<CountryList />} />
          <Route path="/land/:slug" element={<CountryDetailPage />} />
        </Route>
      </Route>
    </Routes>
  );
}
