import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { ListItemText } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface VListProps {
  contentArray: string [];
  icon?: JSX.Element;
}

export default function VList({contentArray, icon}: VListProps) {
  return (
    <List>
      {contentArray.map((text) => (
        <ListItem sx={{ pt: 0, pb: 0 }} key={text}>
          <ListItemIcon sx={{ minWidth: '36px' }}>
            {icon ? (
              icon
            ) : (
              <ChevronRightIcon sx={{ color: 'primary.main' }} />
            )}
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      ))}
    </List>
  );
}
