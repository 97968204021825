import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Flag from '../../components/Flag';

interface DetailPageHeadingProps {
  iso3: string,
  name: string
}

export default function DetailPageHeading({iso3, name}: DetailPageHeadingProps) {
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Flag iso={iso3} name={name} />
        <Typography variant="h1" sx={{ pl: 2 }}>
          Visum für {name}
        </Typography>
      </Box>
      {/* 
      ############## text with visa kinds ##############
      Hier findest du die verschiedenen Visa-Arten die es für {name}{' '}
      gibt. <br />
      Alle Kontaktdaten und Öffnungszeiten der Botschaft und Konsulate. */}
      <Typography variant="h6" component="p" sx={{ mb: 5 }}>
        Alle Kontaktdaten und Öffnungszeiten der Botschaft und Konsulate.
      </Typography>
    </>
  );
}
