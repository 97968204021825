import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { ReactComponent as Logo } from '../assets/images/visum-suche-navbar.svg';

export default function MainNavigation() {
  return (
    <AppBar position="static">
      <Container maxWidth="lg" disableGutters={true}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            <Link href="/" underline='none'>
              <Box sx={{
                maxWidth: '180px',
                display: 'flex',
                height: {xs: '28px', sm: '30px'},
              }}>
                <Logo title='Visum Suche Logo'/>
              </Box>
            </Link>
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
}