import { useState, useEffect } from 'react';
import { getCountries } from '../../api/apiRequests';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Flag from '../../components/Flag';
import { Country } from '../../api/models';

export default function CountryList() {
  const [countries, setCountries] = useState<Country[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadCountries = async () => {
      try{
        const response = await getCountries();
        setCountries(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setCountries([])
        setLoading(false);
      }
    };
    loadCountries();
  }, []);

  return (
    <>
      {' '}
      {!loading &&
        countries.map((country) => (
          <Card key={country.iso} sx={{ m: 2, maxWidth: 400 }} elevation={3}>
            <CardActionArea
              href={`/land/${country.slug}`}
              sx={{
                p: 1.5,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Flag
                iso={country.iso_alpha_3}
                name={country.name}
              />
              <Typography sx={{ pl: 2, mt: 0 }} variant={'h6'}>
                {' '}
                {country.name}{' '}
              </Typography>
              <ArrowForwardIosIcon sx={{ marginLeft: 'auto' }} />
            </CardActionArea>
          </Card>
        ))}
    </>
  );
}
