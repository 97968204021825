import { BrowserRouter } from 'react-router-dom';

// routing
import RouterConfig from './navigation/RouterConfig';

// styles
import './styles/App.css';

export default function App() {
  return (
    <BrowserRouter>
      <RouterConfig />
    </BrowserRouter>
  );
}
