import { createElement, Fragment, useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import type { Root } from 'react-dom/client';
import Box from '@mui/material/Box';
import { getAlgoliaResults, autocomplete } from '@algolia/autocomplete-js';
import algoliasearch from 'algoliasearch/lite';
import '@algolia/autocomplete-theme-classic';
import CountryItem, { CountryHit } from './CountryItem';

const appId = 'DLQZ47OK24';
const apiKey = process.env.REACT_APP_ALGOLIA_FRONTEND_API_KEY;
const searchClient = algoliasearch(appId, apiKey);

interface AutocompleteSearchProps {
  addClass?: {root: string},
  placeholder? : string,
  autoFocus?: boolean,
}  
export default function AutocompleteSearch({addClass, placeholder='Suchen…', autoFocus=false}: AutocompleteSearchProps) {
  // containerRef to mount the autocomplete to
  const containerRef = useRef<HTMLDivElement | null>(null);
  const panelRootRef = useRef<Root | null>(null);
  const rootRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    // detachedMediaQuery: switch off detached mode (fullsceen search on mobile)
    const search = autocomplete<CountryHit>({
      container: containerRef.current,
      placeholder: placeholder,
      detachedMediaQuery: 'none',
      classNames: addClass,
      autoFocus: autoFocus,
      getSources({ query }) {
        return [
          { 
            sourceId: 'countries',
            getItemUrl({ item }) {
              return `${process.env.REACT_APP_BASE_URL}/land/${item.slug}`;
            },
            getItems() {
              return getAlgoliaResults<CountryHit>({
                searchClient,
                queries: [
                  {
                    indexName: `${process.env.REACT_APP_ENVIRONMENT}_visum_suche`,
                    query,
                  },
                ],
              });
            },
            templates: {
              item({ item, components }) {
                return <CountryItem hit={item} components={components} />;
              },
              noResults() {
                return 'Leider nichts gefunden...';
              },
            },
          },
        ];
      },
      renderer: { createElement, Fragment, render: () => { /* empty function to silence warning */ } },
      render({ children }, root) {
        if (!panelRootRef.current || rootRef.current !== root) {
          rootRef.current = root;

          panelRootRef.current?.unmount();
          panelRootRef.current = createRoot(root);
        }

        panelRootRef.current.render(children);
      },
    });

    return () => {
      search.destroy();
    };
  }, [addClass, placeholder, autoFocus]);

  return <Box ref={containerRef}/>
}

