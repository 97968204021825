import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import EmbassyWebpage from './EmbassyWebpage';
import EmbassyContact from './EmbassyContact';
import { getCountryEmbassies } from '../../api/apiRequests';
import { Embassy } from '../../api/models';

interface EmbassiesProps {
  countryName: string;
  embassyLinks: string[];
}

export default function Embassies({countryName, embassyLinks}: EmbassiesProps) {
  const [embassies, setEmbassies] = useState<Embassy[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadEmbassies = async () => {
      try{
        const response = await getCountryEmbassies(embassyLinks);
        let embassyArray:Embassy[] = [];
        response.forEach((responseEmbassy) => embassyArray.push(responseEmbassy.data));
        setEmbassies(embassyArray);
        setLoading(false);
      } catch(error){
        setEmbassies([]);
        setLoading(false);
        console.log(error);       
      }
    };
    loadEmbassies();
  }, [embassyLinks]); // rerender when embassies props change

  let embassyContactTiles = null;

  // if there are embassies, return them as embassyContact Tiles JSX
  if (!loading && embassies) {
    embassyContactTiles = embassies.map((embassy) => {
      return (
        <EmbassyContact
          key={embassy.name + embassy.street}
          countryName={countryName}
          embassy={embassy}
        />
      );
    });
  }

  return (
    <>
      <Typography variant="h2">
        Botschaft und Konsulate von {countryName}
      </Typography>
      {embassies && (
        <EmbassyWebpage embassies={embassies} countryName={countryName} />
      )}

      {embassyContactTiles && (
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'row wrap',
          }}
        >
          {embassyContactTiles}
        </Box>
      )}
    </>
  );
}
