import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CheckIcon from '@mui/icons-material/Check';

import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Link from '@mui/material/Link';

//visum suche specific
import VList from './custom_components/VList';
import VStepHeading from './custom_components/VStepHeading';
import VCoronaBox from './custom_components/VCoronaBox';
import VAccordions from './custom_components/VAccordions';

export default () => {
  const testAccordions = {accordions: [
    {heading: 'test h1', content: 'test description2'},
    {heading: 'test2', content: 'test description1'},
  ]};

  const vaccordions = VAccordions(testAccordions);

  return <>{vaccordions}</>;
};
