//basics
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

//table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

// ------------------ typography ------------------
export default function Types() {
  function createVisaVendors(visavendor:string, price:string|JSX.Element, website:string) {
    return { visavendor, price, website };
  }
  const tooltip = (
    <>
      <Typography>70 – 110€</Typography>
      <Tooltip title="je nach Bearbeitungsdauer">
        <IconButton size="large">
          <InfoIcon />
        </IconButton>
      </Tooltip>
    </>
  );

  const visavendors = [
    createVisaVendors(
      'Visumantrag',
      '109,95 €',
      'https://visumantrag.de/indien',
    ),
    createVisaVendors(
      'König Tours',
      tooltip,
      'https://www.buch-dein-visum.de/indien-visum-beantragen.htm',
    ),
    createVisaVendors(
      'Visabox',
      '199,99 €',
      'https://www.visabox.de/touristenvisum-indien.html',
    ),
  ];

  return (
    <Container maxWidth="lg">
      {/* Mui Typogrophy */}
      <Box sx={{ width: '100%', maxWidth: 500, fontFamily: 'Open Sans' }}>
        <Typography variant="h1" component="div" gutterBottom>
          h1. Heading
        </Typography>

        <Typography variant="body1" gutterBottom>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
          blanditiis tenetur unde suscipit, quam beatae rerum inventore.
        </Typography>

        <Typography variant="h2" gutterBottom component="div">
          h2. Heading
        </Typography>

        <Typography variant="body1" gutterBottom>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
          blanditiis tenetur unde suscipit, quam beatae rerum inventore.
        </Typography>

        <Typography variant="h3" gutterBottom component="div">
          h3. Heading
        </Typography>

        <Typography variant="body1" gutterBottom>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
          blanditiis tenetur unde suscipit, quam beatae rerum inventore.
        </Typography>

        <Typography variant="h4" gutterBottom component="div">
          h4. Heading
        </Typography>

        <Typography variant="body1" gutterBottom>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
          blanditiis tenetur unde suscipit, quam beatae rerum inventore.
        </Typography>

        <Typography variant="h5" gutterBottom component="div">
          h5. Heading
        </Typography>

        <Typography variant="body1" gutterBottom>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
          blanditiis tenetur unde suscipit, quam beatae rerum inventore.
        </Typography>

        <Typography variant="h6" gutterBottom component="div">
          h6. Heading
        </Typography>

        <Typography variant="body1" gutterBottom>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
          blanditiis tenetur unde suscipit, quam beatae rerum inventore.
        </Typography>

        <br />
        <br />

        <Typography variant="subtitle1" gutterBottom component="div">
          subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Quos blanditiis tenetur
        </Typography>
        <Typography variant="subtitle2" gutterBottom component="div">
          subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Quos blanditiis tenetur
        </Typography>
        <Typography variant="body1" gutterBottom>
          body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
          blanditiis tenetur unde suscipit, quam beatae rerum inventore
          consectetur, neque doloribus, cupiditate numquam dignissimos laborum
          fugiat deleniti? Eum quasi quidem quibusdam.
        </Typography>
        <Typography variant="body2" gutterBottom>
          body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
          blanditiis tenetur unde suscipit, quam beatae rerum inventore
          consectetur, neque doloribus, cupiditate numquam dignissimos laborum
          fugiat deleniti? Eum quasi quidem quibusdam.
        </Typography>
        <Typography variant="button" display="block" gutterBottom>
          button text
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          caption text
        </Typography>
        <Typography variant="overline" display="block" gutterBottom>
          overline text
        </Typography>
      </Box>

      {/* Mui Tables */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead sx={{ backgroundColor: 'primary.main' }}>
            <TableRow>
              <TableCell sx={{ color: 'white' }}>Visum Dienstleister</TableCell>
              <TableCell sx={{ color: 'white' }}>Preis</TableCell>
              <TableCell sx={{ color: 'white' }}>Website</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visavendors.map((row) => (
              <TableRow key={row.visavendor}>
                <TableCell>{row.visavendor}</TableCell>
                <TableCell>{row.price}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    size="small"
                    endIcon={<OpenInNewIcon />}
                    href={row.website}
                    target="_blank"
                  >
                    Zum Anbieter
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
