import Paper from '@mui/material/Paper';

interface FlagProps {
  iso: string;
  name: string;
}

export default function Flag({iso, name}: FlagProps) {
  // all flags are set to 2:3 ratio, this is the best to fit in all, see: https://medium.com/@xawnia/aspect-ratios-of-flags-7d2a1ced04ea

  // make the directory with flag images accesible through webpack, usefull when
  // true as second parameter to use also subdirectories
  const flagDirectory = require.context('../assets/icons/flags', true);
  return (
    <Paper
      elevation={3}
      component="img"
      src={flagDirectory(`./${iso.toLowerCase()}.svg`)}
      title={"Flagge von " + name}
      sx={{
        borderRadius: 1,
        display: 'flex',
        height: 40,
        width: 60,
        backgroundSize: 'cover',
        objectFit: 'cover',
      }}
    />
  );
}
