import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import VerifiedBadge from '../../assets/icons/verified-badge.svg';
import { Embassy } from '../../api/models';

interface EmbassyWebpageProps{
  countryName: string;
  embassies: Embassy[];
}

export default function EmbassyWebpage({ countryName, embassies }: EmbassyWebpageProps) {
  let webpage = '';

  // if there is an main embassy that has a webpage return it, otherwise return null
  for (const embassy of embassies) {
    if (embassy.name === 'Botschaft') {
      webpage = embassy.webpage;
    }
  }
  if (webpage) {
    return (
      <Box
        sx={{
          borderRadius: 1,
          p: 1,
          mt: 1.5,
          mb: 1.5,
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'row', 
          alignItems: 'center' 
        }}
        >
          <img
            src={VerifiedBadge}
            alt="Verifiziert Icon"
            height="22px"
            width="22px"
          />
          <Typography component="p" variant="h6" sx={{ ml: 1 }}>
            Offizielle Website der Botschaft von {countryName}:
          </Typography>
        </Box>
        <Link href={webpage} rel="noopener" underline="none">
          {webpage}
        </Link>
      </Box>
    );
  } else {
    return null;
  }
}
