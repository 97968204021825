import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface VAccordionsProps {
  accordions: {
    heading: string;
    content: string | JSX.Element;
  }[]
}

export default function VAccordions ({accordions}: VAccordionsProps) {
  let allAccordions = accordions.map((accordion, index) => {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`"panel${index}a-content"`}
          id={`"panel${index}a-header"`}
        >
          <Typography>{accordion.heading}</Typography>
        </AccordionSummary>
      <AccordionDetails>
        <Typography> {accordion.content} </Typography>
      </AccordionDetails>
      </Accordion>
    )})

  return allAccordions.map((accordion) => <> {accordion} </>);
};
