import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Flag from '../Flag';

interface InProcessProps {
  iso: string,
  name: string
}

export default function InProcess({iso, name} :InProcessProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <Flag iso={iso} name={name} />
      <Typography variant="h2" component="h1">
        Sorry... <br />
        die Visa Infos für{' '}
        <Box component="span" sx={{ color: 'primary.main' }}>
          {name}
        </Box>{' '}
        sind noch in Bearbeitung.
      </Typography>
    </Box>
  );
}
