import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CheckIcon from '@mui/icons-material/Check';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

//visum suche specific
import flag from '../assets/icons/flags/cub.svg';
import touristenkarte from '../assets/images/countries/cuba/cuba-tourist-card-600x275.jpg';
import VList from '../components/custom_components/VList';

export default () => {
  function createVisaVendors(
    visavendor: string,
    delivery: string,
    deliveryTime: string,
    price: string,
    website: string,
  ) {
    return { visavendor, delivery, deliveryTime, price, website };
  }

  const visavendors = [
    createVisaVendors(
      'Cuba Buddy',
      '0,95 €, 49,00 € (Express)',
      '1-2 Werktage',
      '25,90 €',
      'https://shop.cuba-buddy.de/',
    ),
    createVisaVendors(
      'CubaVisa',
      '3,50 €, ab 30 € kostenlos',
      '2-4 Werktage',
      '29,00 €',
      'https://www.cubavisa.net/de/product/touristenvisum-fur-kuba/',
    ),
    createVisaVendors(
      'Reisebüro Govea',
      '0,90 €',
      '2-5 Werktage	',
      '26,90 €',
      'https://www.touristenkarte-kuba.de/',
    ),
    createVisaVendors(
      'König Tours',
      '-',
      '3 Stunden – 7 Werktage',
      '35,00€ - 50,00€',
      'https://www.buch-dein-visum.de/visum-beantragen.htm?vzland=38&vsland=77&vtyp=139',
    ),
  ];

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Box component="img" src={flag} alt="Cuba Flagge" sx={{maxWidth: '70px', mr: 2,}}/>
        <Typography variant="h1">Visum für Kuba</Typography>
      </Box>

      <Typography variant="h6" component="p">
        Welches Visum du für deine Reise nach Kuba benötigst und wie es schnell,
        günstig und sicher klappt erkläre ich dir hier.
      </Typography>

      {/* instruction */}
      <Typography variant="h2">Touristenvisum A1 (Touristenkarte)</Typography>

      <Typography>
        Für die Einreise nach Kuba benötigt man lediglich eine Touristenkarte
        und muss eine Auslandskrankenversicherung vorweisen können. Das gleiche
        gilt auch für Kleinkinder und Kinder.
      </Typography>

      <Typography variant="h3">
        Was man für einen Kuba Urlaub benötigt
      </Typography>
      <Typography>
        Für die Einreise musst du folgendes auf jeden Fall bei dir haben:
      </Typography>

      <VList
        contentArray={[
          'Reisepass (mind. 6 Monate gültig)',
          'Touristenkarte',
          'Auslandskrankenversicherung (ausgedruckte Bestätigung)',
          'Rückflugticket',
        ]}
        icon={<CheckIcon sx={{ color: 'success.main' }} />}
      />

      <Typography variant="h3">Touristenkarte</Typography>

      <Paper
        elevation={2}
        sx={{
          display: 'flex',
          alignSelf: 'start',
          borderRadius: 1,
          overflow: 'hidden',
        }}
      >
        <img
          src={touristenkarte}
          alt="Beispiel einer Touristenkarte für Kuba"
          height="auto"
          width="100%"
        />
      </Paper>

      <Typography>
        Die Touristenkarte muss vor Einreise in Großbuchstaben und Druckschrift
        ausgefüllt werden. Man sollte darauf achten, dass man auf der
        Touristenkarte keine Korrekturen vornimmt, falls etwas durchgestrichen
        ist verliert die Touristenkarte ihre Gültigkeit. Die Angaben die man
        dabei machen muss sind:
      </Typography>

      <VList
        contentArray={[
          'Vorname',
          'Nachname',
          'Geburtsdatum',
          'Reisepassnummer',
          'Nationalität (in englisch)',
        ]}
      />

      <Typography>
        Eine Hälfte der Touristenkarte wird von dem Grenzbeamten in Kuba
        eingesammelt. Die andere Hälfte solltest Du gut aufbewahren, da man sie
        bei der Ausreise wieder benötigt.
      </Typography>

      <Typography variant="h3">
        So erhältst du die Touristenkarte für deinen Kuba-Urlaub am günstigsten
      </Typography>
      <Typography>
        Falls man über eine Reisegesellschaft gebucht hat, bekommt man hier
        meist auch die Touristenkarte dazu. Verschiedene Airlines wie
        beispielsweise Condor bieten die Touristenkarte auch am Flughafen an. Am
        einfachsten bekommt man die Touristenkarte allerdings von einem Online
        Anbieter. Zum einen spart man sich den Weg und geht gleichzeitig auf
        Nummer sicher.
      </Typography>

      <Typography>
        Hier siehst du einen aktuellen Preisvergleich für die Kuba
        Touristenkarte je Visum-Anbieter, sowie deren Versandkosten und Dauer:
      </Typography>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead sx={{ backgroundColor: 'primary.main' }}>
            <TableRow>
              <TableCell sx={{ color: 'white' }}>Visum Dienstleister</TableCell>
              <TableCell sx={{ color: 'white' }}>Versand</TableCell>
              <TableCell sx={{ color: 'white' }}>
                Bearbeitungsdauer (nicht Versand)
              </TableCell>
              <TableCell sx={{ color: 'white' }}>Preis</TableCell>
              <TableCell sx={{ color: 'white' }}>Website</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visavendors.map((row) => (
              <TableRow key={row.visavendor}>
                <TableCell>{row.visavendor}</TableCell>
                <TableCell>{row.delivery}</TableCell>
                <TableCell>{row.deliveryTime}</TableCell>
                <TableCell>{row.price}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    size="small"
                    endIcon={<OpenInNewIcon />}
                    href={row.website}
                    target="_blank"
                  >
                    Zum Anbieter
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h3">So füllst du die Touristenkarte aus</Typography>
      <Typography>
        Dieses Video zeigt dir worauf du beim ausfüllen der Touristenkarte
        achten solltest:
      </Typography>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/o_IfggX-Xrc"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </>
  );
};
