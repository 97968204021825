import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Link from '@mui/material/Link';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

//visum suche specific
import flag from '../assets/icons/flags/rus.svg';
import russiaImage from '../assets/images/countries/russia/russia-inspirational-image.jpg';
import russiaVisa from '../assets/images/countries/russia/russia-visa.jpg';
import VList from '../components/custom_components/VList';
import VStepHeading from '../components/custom_components/VStepHeading';
import VAccordions from '../components/custom_components/VAccordions';

export default () => {
  const aboutVisaAccordion = {accordions: [
    {heading: 'Wann benötige ich ein Visum für Russland?',
     content: 'Alle deutschen Staatsbürger benötigen für die Einreise nach Russland ein Visum. Davon ausgenommen sind Personen, die sich nur im internationalen Transitbereich aufhalten, ohne das Land zu betreten. Eine weitere Ausnahme gilt für Personen, die aufgrund der doppelten Staatsbürgerschaft ebenfalls russische Staatsbürger sind.',
    },
    {heading: 'Welche Art von Visum benötige ich für Russland?',

    content: 
      <>
      <Typography>
        Bei der Art des Visums kommt es ganz darauf an, was du in Russland
        geplant hast. Insgesamt gibt es 7 unterschiedliche Visaarten, die sich
        aber meist nur im Einladungsschreiben unterscheiden. Beispielsweise
        benötigst du bei einem Touristenvisum die Einladung von deiner
        Unterkunft bzw. deinem Hotel. Bei einem Geschäftsvisum ehältst du das
        Schreiben hingegen von dem Unternehmen aus Russland, mit dem du ein
        Meeting hast. <br />
        Du kannst das Einladungsschreiben aber auch einfach Online bestellen.
        Dieser Weg um an die Einladungs zu kommen wird sehr häufig gewählt.
        wie du das machst und welche Anbieter es gibt werde ich dir später
        erklären.
      </Typography>

      <VList
        contentArray={[
          'Touristenvisum',
          'Geschäftsvisum (Für Geschäftstermine, Verhandlungen etc.)',
          'Arbeitsvisum (Aufnehmen einer Erwerbstätigkeit in Russland)',
          'Studienvisum (kostenlos)',
          'Humanitärvisum',
          'Privatvisum (Besuch von Privatpersonen in Russland)',
          'Transitvisum (Bei der Durchreise, aber nur nötig, falls der Transitbereich im Flughafen verlassen wird)',
        ]}
      />
    </>,
    },
    {
      heading: 'Wie viel kostet das Visum für Russland?',
      content: <>
        <Typography>
          Die Kosten für das Visum sind davon abhängig, auf welchem Weg du das
          Visum beantragst: Direkt über das Konsulat, das russische Visazentrum
          oder durch eine private Agentur. Hinzu kommen die Kosten für eine
          Reisekrankenversicherung sowie das Einladungsschreiben, falls man noch
          keines hat.
          <img
            src={russiaVisa}
            alt="Abbildung des Visums für Russland"
            height="auto"
            width="100%"
          />
        </Typography>
        <Typography variant="h6" component="p">
          Hier habe ich für dich zwei Beispielrechnungen:
        </Typography>
        <Typography>
          <br />
          <strong>Direkt im Konsulat (nur vor Ort mit Termin):</strong> <br />
          Bearbeitungsgebühren Standard: 35€ <br />
          Einladungsschreiben: 10€ <br />
          Reisekrankenversicherung: 10€ <br />
          ________________________________ <br />
          <strong>Insgesamt: 55€</strong>
        </Typography>
        <Typography>
          <br />
          <strong>
            Russisches Visazentrum (+Postweg direkt nach Hause):
          </strong>{' '}
          <br />
          Servicegebühren russisches Visazentrum: 27€ <br />
          Lieferung, versichert mit DHL: 27€ (optional) <br />
          Bearbeitungsgebühren Standard: 35€ <br />
          Einladungsschreiben: 10€ <br />
          Reisekrankenversicherung: 10€ <br />
          ________________________________ <br />
          <strong>Insgesamt: 114€</strong>
        </Typography>
      </>,
    },
    { heading:'Wie lange dauert es bis ich das Visum erhalte?',

      content:
      <>
        <Typography>
          Je nachdem wie viel Zeit Dir zur Verfügung steht gibt es
          unterschiedliche Möglichkeiten um an das Visum zu kommen.
        </Typography>
        <Typography>
          <strong>Normal</strong> <br />
          Es wird empfohlen sich mindestens 1 Monat vor der Reise nach Russland
          um das Visum zu kümmern. Beantragst Du das Visum direkt im russischen
          Konsulat, kann man mit einer Dauer von normalerweise 10 Kalendertagen
          rechnen. In Extremfällen kann dies laut russischer Botschaft auch bis
          zu 30 Kalendertage dauern. Du musst allerdings beachten, dass du
          frühestens 3 Monate vor Einreise den Antrag für ein Visum einreichen
          kannst.
        </Typography>

        <Typography>
          <strong>Express:</strong> <br />
          Muss es schnell gehen, da man beispielsweise ein sehr gutes Last
          Minute Angebot ergattert hat oder dringend aus geschäftlichen oder
          persönlichen Gründen nach Russland muss, kann man über den Express Weg
          gehen. So kannst Du Dein Visum auch innerhalb von 3 Arbeitstagen
          erhalten. Hierfür liegen die Bearbeitungsgebühren des Konsulats bei
          70€. Zusätzliche Service-Kosten bei einer Abwicklung über das
          russische Visazentrum (VFS) und eine zeitliche Verzögerung kommen
          hinzu.
        </Typography>
      </>,
    },
  ]};

  function createVisaVendors(visavendor: string, price: string, website:string) {
    return { visavendor, price, website };
  }

  const visavendors = [
    createVisaVendors(
      'König Tours',
      '95,00 €',
      'https://www.russland-visum.eu/',
    ),
    createVisaVendors(
      'Visum-Russland.org',
      '110,00 €',
      'https://www.visum-russland.org/visumantrag-russland/',
    ),
    createVisaVendors('Servisum', '123,80 €', 'https://visumantrag.de/indien'),
  ];

  function createInviteLetterVendors(
    inviteVendor: string,
    price: string,
    deliveryTime: string,
    delivery: string,
    website: string,
  ) {
    return { inviteVendor, price, deliveryTime, delivery, website };
  }

  const inviteLetterVendors = [
    createInviteLetterVendors(
      'Fortuna Travel',
      '9,99 €',
      '<1 Stunde',
      'E-Mail',
      'https://visatoruss.com/de/',
    ),
    createInviteLetterVendors(
      'iVisa',
      '18 €',
      'Sofort nach Zahlung',
      'Direkt Download, E-Mail',
      'https://ivisaonline.com/de/',
    ),
    createInviteLetterVendors(
      'Russland Mal Anders',
      '20 €',
      '1 Werktag',
      'Fax und E-Mail',
      'https://russland-anders.com/visa-und-einladungen/touristen-einladungen/',
    ),
    createInviteLetterVendors(
      'Servisum',
      '41,65 €',
      '3 Werktage',
      '-',
      'https://www.servisum.de/fileadmin/redakteure/pdf/RUS-VSL-Bestellschein.pdf',
    ),
  ];

  const createinsuranceVendor = (insuranceVendor: string, price: string, website: string) => {
    return { insuranceVendor, price, website };
  };

  const insuranceVendors = [
    createinsuranceVendor(
      'ERGO',
      'ab 9,90 €',
      'https://www.ergo.de/de/Produkte/Reiseversicherung/ReisekrankenversicherungDKV',
    ),
    createinsuranceVendor(
      'Envivas',
      'ab 9,80 € (TK-Mitglied)',
      'https://www.envivas.de/tarife/reisekrankenversicherung#/beratung/reise',
    ),
    createinsuranceVendor(
      'HanseMerkur',
      'ab 10,00 €',
      'https://www.hansemerkur.de/auslandskrankenversicherung',
    ),
  ];

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Box component='img' alt='Russland Flagge' src={flag} sx={{maxWidth: '70px', maxHeight: '60px', mr: 2,}} />
        <Typography variant="h1">Visum für Russland</Typography>
      </Box>

      <Typography variant="h6" component="p">
        Welches Visum du für deine Reise nach Russland benötigst und wie es
        schnell, günstig und sicher klappt erfährst du hier
      </Typography>

      <Paper
        elevation={2}
        sx={{
          display: 'flex',
          alignSelf: 'start',
          borderRadius: 1,
          overflow: 'hidden',
        }}
      >
        <img
          src={russiaImage}
          alt="Russland als Reiseziel"
          height="auto"
          width="100%"
        />
      </Paper>

      {/* instruction */}
      <Typography>
        Der Visum-Antrag für Russland ist nicht ganz leicht und beinhaltet
        einiges an Bürokratie. Damit bei deiner Reise nach Russland nichts
        dazwischen kommt haben wir dir hier eine Übersicht zusammmengestellt.
        Wir erklären dir wie du schnell & günstig an das Visum kommst und was du
        zu beachten hast!
      </Typography>

      <Typography>
        Russland, größtes Land der Erde, ist ein immer beliebteres Reiseziel bei
        Touristen. Jährlich zieht es 24,5 Millionen Reisende in die Russische
        Föderation mit ihrer vielfältigen Landschaft und Sehenswürdigkeiten.
        Auch die Wirtschaft im Land wächst stetig, wodurch Russland
        gleichermaßen für Geschäftsreisende ein beliebtes Ziel ist.
      </Typography>

      <Typography variant="h2">Über das Russland-Visum</Typography>

      {VAccordions(aboutVisaAccordion)}

      <Typography variant="h2">
        Welche Dokumente benötige ich für das Visum?
      </Typography>

      <Typography>
        Hier findest du eine Checkliste mit den Unterlagen, die du für das Visum
        haben solltest. Später werde ich dir genau erklären, wie du zu den
        notwendigen Dokumenten wie Einladungsschreiben oder Antragsformular
        kommst.
      </Typography>
      <VList
        contentArray={[
          'Antragsformular für das Visum',
          'Reisepass (keine Kopie, mind. 6 Monate gültig)',
          'Einladungsschreiben',
          'Reisekrankenversicherung',
          'Rückkehrwilligkeit, Nachweis, dass man vor hat wieder auszureisen',
          'Passbild (3,5 × 4,5 cm)',
        ]}
      />

      <Typography variant="h2">Wie du an dein Visum kommst</Typography>
      <Typography>
        Hast du alle notwendigen Dokumente zusammen, gibt es grundsätzlich 3
        Möglichkeiten, wie du das Visum beantragen kannst:
      </Typography>

      <VList
        contentArray={[
          'Russisches Konsulat (Low-Budget Empfehlung)',
          'Russisches Visa-Zentrum bzw. VFS (Empfehlung)',
          'Private Agentur',
        ]}
      />
      <Typography sx={{ mb: 2 }}>
        Im Folgenden werde ich dir die Alternativen genauer erklären und auf
        Vor- und Nachteile eingehen.
      </Typography>

      <VStepHeading stepNumber={1} stepHierarchy='h3' stepHeading='Russisches Konsulat (Low-Budget Empfehlung)' />
      <Grid container spacing={2} alignItems="flex-start" sx={{ mt: 2, mb: 2 }}>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12}>
            <Typography sx={{ color: 'success.main' }}>
              <strong>Vorteile</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <VList
              contentArray={[
                'Günstigste Möglichkeit um das Visum zu beantragen',
              ]}
              icon={<ChevronRightIcon sx={{ color: 'success.main' }} />}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={6}>
          <Grid item xs={12}>
            <Typography sx={{ color: 'error.main' }}>
              <strong>Nachteile</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <VList
              contentArray={[
                'Nur vor Ort bei einem der Standorte möglich',
                'Termin notwendig',
                'Teilweise Wartezeit und Warteliste',
              ]}
              icon={<ChevronRightIcon sx={{ color: 'error.main' }} />}
            />
          </Grid>
        </Grid>
      </Grid>
      <Typography variant="h6" component="p">
        Kosten
      </Typography>
      <Typography>
        Normale Visagebühren: 35€ <br />
        Express Visagebühren (3 Werktage): 70€
      </Typography>

      <Typography variant="h4">
        Alle Adressen der Botschaft und Konsulate:
      </Typography>

      {/* anschrift botschaft und generalkonsulate  */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              backgroundColor: grey[200],
              borderRadius: 1,
              p: 1,
              height: '100%',
            }}
          >
            <Typography variant="h6" component="p">
              Berlin – Botschaft Russland
            </Typography>
            <Typography>
              Behrenstraße 66 <br />
              10117 Berlin
            </Typography>
            <br />
            <Typography sx={{ color: 'primary.main' }}>
              <strong>Zuständigkeit:</strong>
            </Typography>
            <Typography>
              Berlin <br />
              Brandenburg <br />
              Sachsen-Anhalt <br />
              Mecklenburg-Vorpommern
            </Typography>
            <br />
            <Button
              href="http://berlin.kdmid.ru/"
              target="_blank"
              variant="outlined"
            >
              Termin vereinbaren
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              backgroundColor: grey[200],
              borderRadius: 1,
              p: 1,
              height: '100%',
            }}
          >
            <Typography variant="h6" component="p">
              Bonn - Generalkonsulat
            </Typography>
            <Typography>
              Waldstr. 42 <br />
              53177 Bonn
            </Typography>
            <br />
            <Typography sx={{ color: 'primary.main' }}>
              <strong>Zuständigkeit:</strong>
            </Typography>
            <Typography>
              Nordrhein-Westfalen <br />
              Rheinland-Pfalz <br />
              Saarland
            </Typography>
            <br />
            <Button
              href="http://bonn.kdmid.ru/"
              target="_blank"
              variant="outlined"
            >
              Termin vereinbaren
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              backgroundColor: grey[200],
              borderRadius: 1,
              p: 1,
              height: '100%',
            }}
          >
            <Typography variant="h6" component="p">
              Frankfurt - Generalkonsulat
            </Typography>
            <Typography>
              Eschenheimer Anlage 33/34 <br />
              60318 Frankfurt am Main
            </Typography>
            <br />
            <Typography sx={{ color: 'primary.main' }}>
              <strong>Zuständigkeit:</strong>
            </Typography>
            <Typography>
              Baden-Württemberg <br />
              Hessen
            </Typography>
            <br />
            <Button
              href="http://frankfurt.kdmid.ru/"
              target="_blank"
              variant="outlined"
            >
              Termin vereinbaren
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              backgroundColor: grey[200],
              borderRadius: 1,
              p: 1,
              height: '100%',
            }}
          >
            <Typography variant="h6" component="p">
              Hamburg - Generalkonsulat
            </Typography>
            <Typography>
              Am Feenteich 20 <br />
              22085 Hamburg
            </Typography>
            <br />
            <Typography sx={{ color: 'primary.main' }}>
              <strong>Zuständigkeit:</strong>
            </Typography>
            <Typography>
              Bremen <br />
              Hamburg <br />
              Niedersachsen <br />
              Schleswig-Holstein
            </Typography>
            <br />
            <Button
              href="http://hamburg.kdmid.ru/queue-de/"
              target="_blank"
              variant="outlined"
            >
              Termin vereinbaren
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              backgroundColor: grey[200],
              borderRadius: 1,
              p: 1,
              height: '100%',
            }}
          >
            <Typography variant="h6" component="p">
              Leipzig - Generalkonsulat
            </Typography>
            <Typography>
              Turmgutstr. 1 <br />
              04155 Leipzig
            </Typography>
            <br />
            <Typography sx={{ color: 'primary.main' }}>
              <strong>Zuständigkeit:</strong>
            </Typography>
            <Typography>
              Sachsen <br />
              Thüringen
            </Typography>
            <br />
            <Button
              href="http://leipzig.kdmid.ru/queue-de/"
              target="_blank"
              variant="outlined"
            >
              Termin vereinbaren
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              backgroundColor: grey[200],
              borderRadius: 1,
              p: 1,
              height: '100%',
            }}
          >
            <Typography variant="h6" component="p">
              München - Generalkonsulat
            </Typography>
            <Typography>
              Maria-Theresia-Straße 17 <br />
              81675 München
            </Typography>
            <br />
            <Typography sx={{ color: 'primary.main' }}>
              <strong>Zuständigkeit:</strong>
            </Typography>
            <Typography>Bayern</Typography>
            <br />
            <Button
              href="http://munchen.kdmid.ru/"
              target="_blank"
              variant="outlined"
            >
              Termin vereinbaren
            </Button>
          </Box>
        </Grid>
      </Grid>

      <VStepHeading stepNumber={2} stepHierarchy='h3' stepHeading='Russisches Visazentrum – Offiziell authorisierter Visa-Dienstleister' />
  
      <Typography>
        Das russische Visazentrum ist eine offizielle Agentur für die
        Vermittlung von Reisevisa für Russland. Dabei koordinierte das russische
        Visazentrum die Ausstellung deines Visums und übernimmt für dich die
        Kommunikation mit dem Konsulat. <br />
        Auch die russischen Konsulate leiten häufig an das russische Visazentrum
        weiter, da es für sie eine Arbeitsentlastung darstellt. Außerdem ist
        dies der häufigste Weg unkompliziert an das Visum für Russland zu
        kommen.
      </Typography>

      <Grid container spacing={2} alignItems="flex-start" sx={{ mt: 2, mb: 2 }}>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12}>
            <Typography sx={{ color: 'success.main' }}>
              <strong>Vorteile</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <VList
              contentArray={[
                'Einfache Abwicklung',
                'Möglichkeit über den Postweg',
                'Beschleunigte Terminvergabe',
                'Bessere Öffnungszeiten',
                'Meist kürzere Wartezeiten',
              ]}
              icon={<ChevronRightIcon sx={{ color: 'success.main' }} />}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={6}>
          <Grid item xs={12}>
            <Typography sx={{ color: 'error.main' }}>
              <strong>Nachteile</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <VList
              contentArray={['Zusätzliche Servicegebühren']}
              icon={<ChevronRightIcon sx={{ color: 'error.main' }} />}
            />
          </Grid>
        </Grid>
      </Grid>

      <Typography>
        <strong>Kosten:</strong> <br />
        Insgesamt kostet das Visum 62 €, da zu den 35 € Gebühren für das
        Konsulat 27 € Servicekosten hinzukommen.
      </Typography>

      <Button
        variant="outlined"
        href="https://russia-visacentre.com/de/visa/germany/russia"
        target="_blank"
      >
        Zum russischen Visazentrum
      </Button>

      <VStepHeading stepNumber={3} stepHierarchy='h3' stepHeading='Private Agenturen' />    

      <Typography>
        Eine weitere Alternative ist die Abwicklung über eine Private Agenturen.
        Diese Möglichkeit ist die Leichteste, da sich die Agentur um alle Fragen
        sowie die ganze Kommunikation kümmert.
      </Typography>

      <Grid container spacing={2} alignItems="flex-start" sx={{ mt: 2, mb: 2 }}>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12}>
            <Typography sx={{ color: 'success.main' }}>
              <strong>Vorteile</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <VList
              contentArray={[
                'Leichteste Möglichkeit ein Visum für Russland zu erhalten',
                'Sehr gute und individuelle Beratung',
              ]}
              icon={<ChevronRightIcon sx={{ color: 'success.main' }} />}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={6}>
          <Grid item xs={12}>
            <Typography sx={{ color: 'error.main' }}>
              <strong>Nachteile</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <VList
              contentArray={['Zusätzliche Servicegebühren']}
              icon={<ChevronRightIcon sx={{ color: 'error.main' }} />}
            />
          </Grid>
        </Grid>
      </Grid>

      <Typography>
        Alle Preise sind für ein Touristenvisum inkl. das dafür benötigte
        Einladungsschreiben sowie der Gebühren für das Konsulat.
      </Typography>

      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead sx={{ backgroundColor: 'primary.main', fontWeight: 700 }}>
            <TableRow>
              <TableCell sx={{ color: 'white' }}>Visum Dienstleister</TableCell>
              <TableCell sx={{ color: 'white' }}>
                Preis <br /> (inkl. Einladungsschreiben)
              </TableCell>
              <TableCell sx={{ color: 'white' }}>Website</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visavendors.map((row) => (
              <TableRow key={row.visavendor}>
                <TableCell>{row.visavendor}</TableCell>
                <TableCell>{row.price}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    size="small"
                    endIcon={<OpenInNewIcon />}
                    href={row.website}
                    target="_blank"
                  >
                    Zum Anbieter
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h2">
        Alle notwendigen Dokumente erhalten: So klappt's!
      </Typography>

      <Typography>
        Da man das Antragsformular erst mit allen anderen Dokumenten
        (Einladungsschreiben, Reisekrankenversicherung etc.) ausfüllen kann, ist
        es ratsam diesen Schritt erst zum Schluss zu erledigen.
        <br />
        <br />
        Die folgenden Daten zu deiner Reise musst du für das Antragsformular
        wissen:
      </Typography>

      <VList
        contentArray={[
          'An- und Abreise müssen angegeben werden, dies wird dann im Visum vermerkt',
          'Anzahl der Ein- und Ausreisen muss feststehen',
          'Angaben zu deiner Reiserote müssen genannt werden. Wie viele Tage du an welchem Ort verbringst.',
          'Für den Antrag benötigst du keine Flugtickets',
        ]}
      />

      
      <VStepHeading stepNumber={1} stepHierarchy='h3' stepHeading='Einladungsschreiben' />
      <Typography>
        Das Einladungsschreiben ist der Beleg dafür, dass du von einer
        Unterkunft, Person oder Unternehmen nach Russland eingeladen wurdest.
        Dabei ist es egal ob du tatsächlich in der Unterkunft übernachtest, die
        in dem Einladungsschreiben genannt ist, oder nicht. Du könntest dich ja
        z.B. auch kurzfristig für ein günstigeres Hotel entschieden haben.
        Wichtig bei dem Einladungsschreiben ist nur, dass du die Einladung aus
        den Orten bekommst, in die du tatsächlich reist.
        <br />
        <br />
        Da es hier oft zu Missverständnissen kommt: Die Hotelreservierung ist
        nicht das Einladungsschreiben.
        <br />
        <br />
        Um an das Einladungsschreiben zu kommen hast du zwei Möglichkeiten:
        Entweder du lässt dir das Einladungsschreiben direkt von deiner
        Unterkunft bzw. Hotel in Russland ausstellen, dies kostet eventuell eine
        Gebühr. Die andere gängige Alternative ist, dass du es dir einfach und
        unkompliziert über eine Agentur online bestellst. Dies klappt auch falls
        du über Airbnb, Booking.com oder ähnliche gebucht hast.
        <br />
        <br />
        Folgend sind ein paar Beispiele für Agenturen, die diesen Service recht
        günstig und zuverlässig anbieten:
      </Typography>

      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead sx={{ backgroundColor: 'primary.main', fontWeight: 700 }}>
            <TableRow>
              <TableCell sx={{ color: 'white' }}>Visum Dienstleister</TableCell>
              <TableCell sx={{ color: 'white' }}>Preis</TableCell>
              <TableCell sx={{ color: 'white' }}>Dauer</TableCell>
              <TableCell sx={{ color: 'white' }}>Zustellung</TableCell>
              <TableCell sx={{ color: 'white' }}>Website</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inviteLetterVendors.map((row) => (
              <TableRow key={row.inviteVendor}>
                <TableCell>{row.inviteVendor}</TableCell>
                <TableCell>{row.price}</TableCell>
                <TableCell>{row.deliveryTime}</TableCell>
                <TableCell>{row.delivery}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    size="small"
                    endIcon={<OpenInNewIcon />}
                    href={row.website}
                    target="_blank"
                  >
                    Zum Anbieter
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <VStepHeading stepNumber={2} stepHierarchy='h3' stepHeading='Auslandskrankenversicherung' />
      <Typography>
        Viele Kreditkarten haben bereits eine Reisekrankenversicherung
        inklusive, diese können dir dann eine Bestätigung zukommen lassen.
        <br />
        <br />
        Falls dies nicht der Fall ist gibt es verschiedene Anbieter mit
        günstigen Kondition. Bei der Wahl der Krankenversicherung musst du
        lediglich beachten, dass die Versicherung eine Mindestdeckung von 30.000
        € bietet. Der Versicherungsschein (Polizze) ist als Bestätigung für das
        Russland-Visum dabei ausreichend.
        <br />
        <br />
        Dies ist bei folgenden Versicherungen der Fall:
      </Typography>

      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead sx={{ backgroundColor: 'primary.main', fontWeight: 700 }}>
            <TableRow>
              <TableCell sx={{ color: 'white' }}>Versicherung</TableCell>
              <TableCell sx={{ color: 'white' }}>Preis</TableCell>
              <TableCell sx={{ color: 'white' }}>Website</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {insuranceVendors.map((row) => (
              <TableRow key={row.insuranceVendor}>
                <TableCell>{row.insuranceVendor}</TableCell>
                <TableCell>{row.price}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    size="small"
                    endIcon={<OpenInNewIcon />}
                    href={row.website}
                    target="_blank"
                  >
                    Zum Anbieter
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
      <VStepHeading stepNumber={3} stepHierarchy='h3' stepHeading='Rückkehrwilligkeit und Finanzierungsnachweis' />
      <Typography>
        Eines der folgenden Dokumente ist laut russischem Konsulat für ein
        Rückkehrwilligkeits-Nachweis ausreichend:
      </Typography>
      <br />
      <Typography fontWeight="bold">Privat oder Touristenreisen:</Typography>
      <VList
        contentArray={[
          'Nachweis eines regelmäßigen Einkommens durch Arbeits- und Verdienstbescheinigung (im Original)',
          'Registrierung der eigenen Firma',
          'Nachweis von Wohneigentum',
          '…oder ähnliche Dokumente',
        ]}
      />

      <br />

      <Typography fontWeight="bold">Geschäftsreisen</Typography>
      <VList
        contentArray={[
          'Registrierung der eigenen Firma',
          'Bestätigung des Arbeitgebers über das Beschäftigungsverhältnis',
        ]}
      />

      <br />

      <Typography fontWeight="bold">
        Folgende Dokumente werden auch akzeptiert:
      </Typography>
      <VList
        contentArray={[
          'Studentenausweis, Immatrikulationsbescheinigung oder BAFöG-Bescheid',
          'Nachweis von Arbeitslosen- oder Haushaltsgeld',
          'Rentenbescheid, Rentnerausweis',
          'Aktueller Kontoauszug',
        ]}
      />

      <VStepHeading stepNumber={4} stepHierarchy='h3' stepHeading='Antragsformular für Russland-Visum' />
      <Typography>
        Hast du nun alle Dokumente und Daten zusammen (Reisekrankenversicherung,
        Finanz-Nachweis, Einladungsschreiben), kannst du das Antragsformular
        ausfüllen. Du findest es auf der Website des russischen Konsulats.{' '}
        <br />
        Zu Beginn gibt es ein paar Informationen, die du dir vor dem Ausfüllen
        durchlesen solltest.
        <br />
        <Link
          href="https://visa.kdmid.ru/PetitionChoice.aspx?AspxAutoDetectCookieSupport=1"
          target="_blank"
        >
          Zum Formular
        </Link>
      </Typography>

      <br />
      <Typography>
        Falls du Hilfe beim Ausfüllen brauchst, kannst du dir gerne dieses Video
        anschauen. Hier wird dir an einem Beispiel sehr gut gezeigt wie du es
        ausfüllst:
      </Typography>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/v2VgwVnb0wQ"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <Typography>
        Das Antragsformular vollständig ausgefüllt? Dann musst du es nur noch
        ausdrucken, dein Passbild aufkleben (das original) und unterschreiben.
        Jetzt hast du alle Dokumente zusammen und kannst damit zum Konsulalt
        (mit Termin), Russisches Visa-Zentrum und alles einreichen!
        <br />
        <br />
        Wir wünschen dir eine schöne Zeit in Russland!
      </Typography>
    </>
  );
};
