import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';

export default function Footer() {
  return (
    <Box component="footer" sx={{ backgroundColor: '#1e0946', py: 2 }}>
      <Container maxWidth="lg" sx={{ color: '#fff' }}>
        <Typography variant="subtitle2" align="center" sx={{ opacity: '0.6' }}>
          <Link color="inherit" href="/impressum">
            Impressum
          </Link>
          |
          <Link color="inherit" href="/datenschutzerklaerung">
            Datenschutzerklärung
          </Link>
        </Typography>

        <Typography
          variant="subtitle2"
          align="center"
          sx={{ opacity: '0.4', pt: 1 }}
        >
          Copyright © 2019-{new Date().getFullYear()} Visum Suche
        </Typography>
      </Container>
    </Box>
  );
}
