import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Embassy } from '../../api/models';

interface EmbassyContactProps{
  embassy: Embassy;
  countryName: string;
}

export default function EmbassyContact({embassy, countryName}:EmbassyContactProps) {
  return (
    <Box
      sx={{
        width: '300px',
        borderRadius: 1,
        backgroundColor: 'primary.lighter',
        padding: 1,
        marginRight: 3,
        marginBottom: 3,
      }}
    >
      <div itemScope itemType="https://schema.org/Organization">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <LocationOnIcon
            sx={{
              color: 'primary.main',
              fontSize: '60px',
            }}
          />
        </Box>
        <Typography component="h3" variant="h6">
          <span itemProp="name">
            {embassy.name} <br />
            {countryName}
          </span>
        </Typography>
        <div
          itemProp="address"
          itemScope
          itemType="https://schema.org/PostalAddress"
        >
          <Typography component="p">
            <span itemProp="streetAddress">
              {embassy.street} {embassy.house_number}
            </span>
          </Typography>
          <Typography component="p">
            <span itemProp="postalCode">{embassy.zip_code} </span>{' '}
            <span itemProp="addressLocality"> {embassy.city} </span>
          </Typography>
        </div>
        {/* if open hours exist include it */}
        {embassy.hours && (
          <Box>
            <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
            <Typography component="p">
              <Typography
                component="span"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ScheduleIcon sx={{ color: 'primary.main' }} />{' '}
                <b>Öffnungszeiten:</b>
              </Typography>
              <span itemProp="openingHours">{embassy.hours}</span>
            </Typography>
          </Box>
        )}
      </div>
    </Box>
  );
}
