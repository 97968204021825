import axios, { AxiosResponse } from 'axios';
import { Country, Embassy, Agent, Service, Visa } from './models';

/* endpoints */
const countryEndpoint = `${process.env.REACT_APP_API_BASE_URL}/countries/`;

/* get specific country */
export const getCountry = (slug?: string): Promise<AxiosResponse> => {
  return axios.get<Country>(countryEndpoint + slug);
}

/* get list of all countries */
export const getCountries = (): Promise<AxiosResponse> => {
  return axios.get<Country[]>(countryEndpoint);
}

/* get visas of country */
export const getCountryVisas = (visaLinks: string[]):Promise<AxiosResponse[]> => {
  return Promise.all(visaLinks.map((visa) => axios.get<Visa>(visa)))
}

/* get embassies of country */
export const getCountryEmbassies = (embassyLinks:string[]) => {
  return Promise.all(embassyLinks.map((embassy) => axios.get<Embassy>(embassy)))
}

export const getCountryAgents = (agentLinks:string[]) => {
  return Promise.all(agentLinks.map((agent) => axios.get<Agent>(agent)))
}

export const getAgentServices = (serviceLinks:string[]) => {
  return Promise.all(serviceLinks.map((service) => axios.get<Service>(service) ))
}


/* Auswärtiges Amt Open Data Schnittstelle */
export async function getAuswertigesAmtCountries() {
  // with axios object creation to have base url only once
  // const client = axios.create({
  //     baseURL: 'https://www.auswaertiges-amt.de/opendata'
  // });

  // only for some requests possible for specific countries, e.g. https://www.auswaertiges-amt.de/de/ReiseUndSicherheit/spaniensicherheit/210534
  const url =
    'https://www.auswaertiges-amt.de/opendata/representativesInGermany';
  try {
    const response = await axios.get(url);
    return { response, isError: false };
  } catch (response) {
    return { response, isError: true };
  }
}
