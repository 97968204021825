import { Outlet } from 'react-router-dom';

import Container from '@mui/material/Container';

export default function BoxedLayout() {
  return (
    <Container maxWidth="md" sx={{ pt: 4, pb: 4 }}>
      <Outlet />
    </Container>
  );
}
